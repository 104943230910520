@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth
}

html {
	scroll-padding-top: 80px;
	/* height of your sticky header */
}

.carousel .slide .legend {
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	position: absolute;
	bottom: 40px;
	left: 50%;
	margin-left: -45%;
	width: 90%;
	border-radius: 10px;
	background: red;
	color: red;
	padding: 10px;
	font-size: 12px;
	text-align: center;
	opacity: 0.25;
	-webkit-transition: opacity .35s ease-in-out;
	-moz-transition: opacity .35s ease-in-out;
	-ms-transition: opacity .35s ease-in-out;
	-o-transition: opacity .35s ease-in-out;
	transition: opacity .35s ease-in-out
}